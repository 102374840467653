import Layout from 'components/Layout';
import React from 'react';
import { ReactQueryConfigProvider } from 'react-query';
import InventoryStatus from 'wms/exports/InventoryStatus';

export default function IraContainer(props) {
  return (
    <ReactQueryConfigProvider
      config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
    >
      <Layout {...props} hideDateFilter>
        <div className="bg-background h-full">
          <InventoryStatus />
        </div>
      </Layout>
    </ReactQueryConfigProvider>
  );
}
