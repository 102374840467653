import { Box, LinearProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TableMUI from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { saveAs } from 'file-saver';
import React, { memo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useAsyncDebounce, useGlobalFilter, useTable } from 'react-table';
import { useGState } from 'state/store';
import fetch from 'utils/fetch';
import { format0, formatDateTime, isBranch } from 'utils/utils';

export const Table = memo(function Table({ columns, data, downloadFile, downldStatus, status }) {
  const { getTableProps, headerGroups, rows, prepareRow, setGlobalFilter } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter // useGlobalFilter!
  );

  const onFilterChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Box style={{ height: '100%', display: 'grid', gridTemplateRows: 'auto 1fr' }}>
      {(status === 'loading' || downldStatus === 'loading') && (
        <LinearProgress
          style={{ position: 'absolute', width: '100%', height: 5 }}
          color="secondary"
        />
      )}
      <Box m={2} display="flex" justifyContent="space-between">
        <TextField
          id="search"
          label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => onFilterChange(e.target.value)}
        />
        <Button variant="outlined" onClick={downloadFile} disabled={downldStatus === 'loading'}>
          Export
        </Button>
      </Box>

      <Box px={2} style={{ overflowY: 'auto' }}>
        <TableMUI size="small" {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    {...column.getHeaderProps()}
                    align={column.align || 'left'}
                    padding={column.padding || 'default'}
                  >
                    {column.render('Header')}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        align={cell.column.align || 'left'}
                        padding={cell.column.padding || 'default'}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </TableMUI>
      </Box>
    </Box>
  );
});

const columns = [
  {
    Header: 'Area Type',
    accessor: 'areaType',
  },
  {
    Header: 'Area Code',
    accessor: 'areaCode',
  },
  {
    Header: 'Zone Code',
    accessor: 'zoneCode',
  },
  {
    Header: 'Bin Code',
    accessor: 'binCode',
    width: 100,
  },
  {
    Header: 'Sku Code',
    accessor: 'skuCode',
  },
  {
    Header: 'Sku Name',
    accessor: 'skuName',
  },
  {
    Header: 'Sku Quality',
    accessor: 'bucket',
  },
  {
    Header: 'Inventory Owner',
    accessor: 'inventoryOwner',
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
    align: 'right',
  },
  {
    Header: 'UOM',
    accessor: 'uom',
  },
  {
    Header: 'Batch',
    accessor: 'batch',
  },
  // {
  //   Header: 'Batch Date',
  //   accessor: 'batchDate',
  // },
  // {
  //   Header: 'Sku Occupancy%',
  //   accessor: 'skuOccupancy',
  //   align: 'right',
  //   Cell: (props) => format0(props.value),
  // },
  {
    Header: 'Bin Occupancy%',
    accessor: 'binOccupancy',
    align: 'right',
    Cell: (props) => format0(props.value),
  },
  {
    Header: 'Updated At',
    accessor: 'updatedAt',
    Cell: (props) => formatDateTime(new Date(props.value)),
  },
];

function InventoryStatus() {
  const { branch } = useGState((s) => ({ date: s.date, branch: s.branch }));
  const { data = [], status } = useQuery(
    Boolean(isBranch(branch)) && ['sbmart_orders', branch?.id],
    (_, branchId) => fetch(`/wms/storage/inventory/dashboard?whId=${branchId}`)
  );

  const [downloadFile, { status: downldStatus }] = useMutation(
    () =>
      fetch({
        url: 'wms/storage/inventory',
        method: 'GET',
        responseType: 'blob',
        wholeResponse: true,
        headers: {
          'Content-Type': 'application/json, blob',
          'x-wh-id': branch.id,
        },
        // params: { branchId, },
      }),
    {
      onSuccess: (data) => {
        saveAs(data.data, data.headers.filename ? data.headers.filename : 'Inventory Status');
      },
    }
  );

  return (
    <Table
      columns={columns}
      data={data}
      status={status}
      downloadFile={downloadFile}
      downldStatus={downldStatus}
    />
  );
}

export default InventoryStatus;

// const mockData = [
//   {
//     binCode: 'abc',
//     skuCode: 'mnop',
//     skuName: 'vicks',
//     inventoryOwner: 'lanister',
//     quantity: 10,
//     batch: 'B412',
//     uom: 'L2',
//     batchDate: 'ghi', // yyyy-mm-dd
//     skuOccupancy: 80.1231, // percent upto 100
//     binOccupancy: 50.1231, //percent upto 100
//     // "updatedAt": string, // iso date string
//   },
//   {
//     binCode: 'abc2',
//     skuCode: 'mnop2',
//     skuName: 'vicks2',
//     inventoryOwner: 'lanister2',
//     quantity: 10,
//     batch: 'B4122',
//     uom: 'L22',
//     batchDate: 'ghi2', // yyyy-mm-dd
//     skuOccupancy: 80.1231, // percent upto 100
//     binOccupancy: 50.1231, //percent upto 100
//     // "updatedAt": string, // iso date string
//   },
// ];
